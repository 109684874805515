<template>
  <div class="py-8 container">
    <div v-if="!rowsData?.rows && !loading" class="text-center">
      <h2 class="text-5xl">目前沒有收藏任何試卷</h2>
      <p class="mt-4 text-2xl">想再次練習的試卷，都可以收藏在這喔!</p>
      <div class="flex justify-center space-x-4">
        <el-button
          class="mt-6"
          size="default"
          @click="$router.push({ path: '/exam/self/list' })"
          >自設試卷</el-button
        >
        <el-button
          type="primary"
          class="mt-6"
          size="default"
          @click="$router.push({ path: '/exam/past/list' })"
          >前往QB題庫</el-button
        >
      </div>
      <img src="@/assets/imgs/role3.png" alt="" class="mt-12 mx-auto" />
    </div>
    <div v-else>
      <div class="flex box items-center">
        <Titles :text="'收藏試卷'" />

        <Count :total="rowsData.total"></Count>

        <Data-Type
          v-model="form.dataType"
          class="sm:w-full w-auto"
          :opts="dataType"
          @update:modelValue="(e) => (form.pg = 1)"
        />

        <El-Select v-model="form.type" class="w-[160px] md:w-auto ml-auto">
          <El-Option :label="'歷年試卷'" value="past">歷年試卷</El-Option>
          <El-Option :label="'AI主題試卷'" value="topic">AI主題試卷 </El-Option>
        </El-Select>

        <Display-Type
          v-if="!isMobile"
          class="ml-4"
          :display="display"
          @choose-type="switchDisplayType"
        />
        <div class="ml-4 flex items-center space-x-4 sm:mt-3">
          <Folder />
        </div>
      </div>
      <div v-loading="loading" class="my-6">
        <List :display="display" :empty-text="'目前沒有收藏任何試卷'" />
      </div>
    </div>
  </div>
</template>

<script>
import { provide, ref, inject } from 'vue';
import { ElButton, ElSelect, ElOption } from 'element-plus';
import { Operation, Edit } from '@element-plus/icons-vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';

import useDialog from '@/hook/useDialog';

import DisplayType from '@/components//filter/DisplayType.vue';
import DataType from '@/components/filter/DataType.vue';
import Titles from '@/components/Titles';
import Count from './_Count.vue';
import useFolder from '@/modules/exam/folder/useFolder';
import Folder from '@/modules/exam/folder/Mini.vue';

import List from '@/modules/exam/list/Index.vue';
import useExam from '@/modules/exam/useExam';
import Exam from '@/api/Exam.js';
import usePgReset from '@/hook/usePgReset.js';
export default {
  components: {
    ElButton,
    Count,
    List,
    Titles,
    Folder,
    DataType,
    DisplayType,
    ElSelect,
    ElOption,
  },
  beforeRouteEnter(to, from, next) {
    usePgReset(to, from, next);
  },
  setup() {
    const isMobile = inject('isMobile');

    const folder = useFolder({ listRouteName: 'Assistant.Question' });
    provide('folder', folder);
    const route = useRoute();

    // 試卷類別(past:收藏歷年試卷/ saveSelf:收藏自設試卷 / self:自設試卷, default to past)
    const exams = useExam({
      method: ref(Exam.mine),
      defaultForm: {
        type: 'past',
      },
    });
    provide('exams', exams);
    exams.fetcher();

    const { saveds, updateSaved } = exams;
    provide('actions', { saveds, updateSaved });

    const display = ref('card');
    const switchDisplayType = (type) => {
      display.value = type;
    };

    const dialog = useDialog();

    const onFilter = (e) => {
      exams.form.filterId = e.filterId;
      exams.form.keyword = null;
      exams.form.keywordType = null;
      dialog.toggle();
    };

    // const onTypeUpdate = () => {
    //   setTimeout(() => {
    //     folder.fetcher();
    //   }, 1000);
    //   // nextTick(() => {
    //   // });
    // };
    // watch(
    //   () => exams.form,
    //   (val, old) => {
    //     console.log(old);
    //     console.log(val);
    //   },
    //   { deep: true }
    // );

    return {
      // onTypeUpdate,
      type: route.params.slug,
      form: exams.form,
      listType: exams.listType,

      dataType: exams.dataType,
      rowsData: exams.rowsData,
      loading: exams.loading,

      Operation,
      Edit,
      display,
      switchDisplayType,
      onFilter,

      isMobile,
    };
  },
};
</script>
